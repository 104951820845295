<template>
  <div class="review-container">
    <span>
      <span v-if="review.reviewNumber > 0" class="review-group">
        <client-only>
          <Stars :size="iconSize" :stars="review.stars" class="review-hearts" />
        </client-only>
        <span class="subtitle" :style="{fontSize: fontSize, lineHeight: fontSize}">
          {{ review.reviewNumber }}
        </span>
      </span>
      <span v-else class="newly-launched">
        Newly launched
      </span>
    </span>

    <span v-if="showHours" class="hours">
      <svgicon name="clock" :width="iconSize" :height="iconSize" />
      <span :style="{fontSize: fontSize, lineHeight: fontSize}">{{ review.hours }} h</span>
    </span>
    <span v-if="showCapacity" class="capacity">
      <svgicon name="max" :width="iconSize" :height="iconSize" />
      <span :style="{fontSize: fontSize, lineHeight: fontSize}">{{ review.capacity }} max</span>
    </span>
  </div>
</template>

<script>
import Stars from '@/components/utils/Stars'
export default {
  name: 'ReviewLine',
  components: {
    Stars
  },
  props: {
    review: {
      type: Object,
      require: true
    },
    showHours: {
      type: Boolean,
      require: false,
      default: () => true
    },
    showCapacity: {
      type: Boolean,
      require: false,
      default: () => true
    },
    iconSize: {
      type: String,
      require: false,
      default: () => '22px'
    },
    fontSize: {
      type: String,
      require: false,
      default: () => '16px'
    }
  }
}
</script>

<style lang="scss" scoped>
.review-container {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  .review-group {
    display: flex;
    align-items: center;
    > .subtitle {
      margin: 0;
      color: #889BAA;
      margin-left: 4px;
    }
  }
  .capacity {
    display: flex;
    align-items: center;
  }
}
.newly-launched {
  background-color: #FFB446 ;
  color: white;
  font-weight: 700;
  padding: 8px;
  font-size: 14px;
}
</style>
